<template>
  <div class="head_top">
    <img src="../../../../../public/images/二级海报/二级页面海报低功耗门铃.jpg" width="100%" height="100%" @click="">
    <!--    室内-->
    <div class="content1">
      <div class="content1_title">
        <div class="content1_title_size">视频门铃</div>
      </div>
      <div class="grid" id="grid">
        <PhotoCard img="images/相机详情/3.低功耗机/3.门铃/DB4/DB4.jpg" names="DB4" ulr="/DB4"></PhotoCard>
      </div>
    </div>


  </div>
</template>

<script>
import PhotoCard from '../../../../../src/components/photo-card.vue'
export default {
  name: "index",
  components: {
    PhotoCard
  },
}
</script>

<style scoped>
.content1 {
  padding-top: 40px;
  width: 80%;
  height: 400px;
  margin: auto;
  /*background: #cd6969;*/
}

.content2 {
  padding-top: 40px;
  width: 80%;
  height: 800px;
  margin: auto;
  /*background: #cd6969;*/
}

.content1_title {
  display: flex;
  justify-content: space-between;
}

.content1_title_size {
  font-size: 20px;
  color: red;
}


#grid {
  /*display: grid;*/
  /*grid-template-columns: repeat(auto-fill, 150px);*/
  /*grid-column-gap: 130px;*/
  /*grid-row-gap: 130px;*/
  align-items: center;
  width: 100%;
  /*justify-content: center;*/
  /*max-width: 800px;*/
  display: flex;
  justify-content: space-evenly;
}

#grid .card {
  background-color: #ccc;
  width: 150px;
  height: 150px;
  transition: all 0.1s ease;
  border-radius: 3px;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  overflow: hidden;
  cursor: pointer;
}

#grid .card:hover {
  -webkit-transform: scale(2);
  transform: scale(2);
  z-index: 2;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

#grid .card:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

#grid .card .reflection {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  transition: all 0.1s ease;
  opacity: 0;
  mix-blend-mode: soft-light;
}

#grid .card img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-filter: grayscale(0.65);
  filter: grayscale(0.65);
  transition: all 0.3s ease;
}

.grid {
  padding-top: 100px;
}

p {
  color: red;
  font-size: 100px;
}
</style>